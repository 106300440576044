import styled from "styled-components"
import { BreakpointMobile, PaddingLeftRightDesktop } from "../GlobalStyle"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
`

export const Wrapper = styled.div`
  display: block;
  width: 100%;
  /* max-width: var(--MaxWidthPage); */
`

export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: auto;

  height: inherit;
`

export const LandingSection = styled.section``

export const LandingTitleTypeOne = styled.h1`
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "30px")};
  font-family: var(--Font1NotCondensed);
  font-size: 42px;
  line-height: 50px;
  color: var(--TextTitleColor1);

  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    font-size: 25pt;
    line-height: 35pt;
  }
`

export const LandingSubTitleTypeOne = styled.h2`
  margin-bottom: 30px;
  font-family: var(--Font1NotCondensed);
  font-size: 30px;
  line-height: 45px;
  color: var(--TextTitleColor1);
  margin-top: 0px;
  font-weight: normal;
`

export const SectionTitle = styled.h2`
  margin-top: 10px;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.5px;
  font-family: var(--Font1NotCondensed);
  margin: auto;
  margin-bottom: 60px;

  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    font-size: 28px;
    line-height: 30px;
  }
`

export const LandingTitleTypeTwo = styled.h2`
  /* max-width: 450px; */
  margin-bottom: 10px;
  color: var(--TextTitleColor1);
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  letter-spacing: 0.2px;

  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    font-size: 22px;
    line-height: 28px;
  }
`
export const LandingParagraphOne = styled.p`
  font-size: var(--ParagraphFontSize);
  line-height: var(--ParagraphLineHeight);
  font-family: var(--Font2);
  color: var(--GreyColorTwo);
  margin-bottom: 30px;
`

export const AppDownloadButton = styled.button`
  background: url(${({ src = "" }) => src});
  width: ${({ width = "200px" }) => width};
  max-width: ${({ width = "200px" }) => width};
  height: 60px;
  object-fit: cover;
  background-repeat: no-repeat;
  color: transparent;
  cursor: pointer;
  border: none;
  outline: none;

  @media only screen and (max-width: 500px) {
    width: 150px;
    height: 25px;
    .download-badge {
    }
  }
  @media only screen and (max-width: 450px) {
    height: 45px;
    .download-badge {
    }
  }
`
