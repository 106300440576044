import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Footer from '../NormalLayout/Footer';
import { PaddingLeftRightDesktop, GlobalStyle, theme, muiTheme } from '../GlobalStyle';
import SEO from '../SEO'
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import {Container, Content, Wrapper} from './styles'
import LandingFooter from './LandingFooter'
import LandingHeader from './LandingHeader'
import { ErrorsProvider } from '../../../providers/ErrorsProvider';
import { AuthProvider } from '../../../providers/AuthProvider';

export function getJWT() {
    if (typeof window != undefined) {
        return localStorage.getItem("jwt")
            ? localStorage.getItem("jwt")
            : "";
    }
}
export const getHeaders = () => {
    if (typeof window != "undefined") {
        let headers = {
            Authorization: getJWT(),
            "Content-Type": "application/json",
        }

        return {
            headers
        }
    }
};


const LandingLayout = ({ children, seoProps, contentStyleProps }) => {
    return (
        <>
            <SEO {...seoProps} />
            <ThemeTopLayout theme={muiTheme}>
                <GlobalStyle />
                <ThemeProvider theme={theme}>
                <ErrorsProvider>
                        <AuthProvider>
                            <Container>
                                <LandingHeader />
                                <Content {...contentStyleProps} >
                                    <Wrapper>
                                        {children}
                                    </Wrapper>
                                </Content>
                                <LandingFooter />
                            </Container>
                        </AuthProvider>
                    </ErrorsProvider>
                </ThemeProvider>
            </ThemeTopLayout>
            {/* <CustomerChat /> */}
        </>
    );
};


LandingLayout.defaultProps = {
    contentStyleProps: {
        paddingLeft: "3%",
        paddingRight: "3%"
    }
}

LandingLayout.propTypes = {
    contentStyleProps: PropTypes.shape({
        paddingLeft: PropTypes.string,
        paddingRight: PropTypes.string
    }),
    children: PropTypes.node.isRequired,
    seoProps: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
        pathname: PropTypes.string,
        image: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
            src: PropTypes.string
        })
    })
}





export default LandingLayout;
