import { Button } from "@material-ui/core"
import styled from "styled-components"
import { BreakpointMobile } from "../../GlobalStyle"

export const StyledFooter = styled.footer`
  background: #2b333f;
  padding-top: 60px;
  padding-bottom: 60px;
  .inner-footer {
    max-width: var(--LandingMaxPageWidth);
    margin: auto;
    padding-right: var(--LandingPagePaddingLeftRight);
    padding-left: var(--LandingPagePaddingLeftRight);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .copyright-info {
    font-family: var(--Font1);
    font-weight: lighter;
    font-size: 12px;
    color: white;
  }

  .footer-row-one {
    display: flex;
    justify-content: space-between;
    .brand {
      width: 131px;
    }

    .call-to-action {
    }
  }

  .footer-row-two {
    margin-top: 45px;
    margin-bottom: 35px;
    padding-top: 45px;
    padding-bottom: 45px;
    border-bottom: 3px solid #343e50;
    border-top: 3px solid #343e50;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 16px 16px 16px 16px;
    grid-gap: 5px;
    height: 100%;
    .link-column {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr;
      grid-gap: 15px;
      color: white;
      a {
        display: block;
        height: inherit;
        line-height: 13px;
        width: 100%;
        color: white;
        font-size: small;
        font-family: var(--Font1);
        :hover {
          text-decoration: underline;
        }
      }
    }
  }

  .footer-row-three {
    display: flex;
    justify-content: space-between;
  }

  .social-media-icons {
    display: grid;
    grid-template-columns: 18px 18px 18px;
    grid-gap: 18px;
    svg {
      height: 18px;
      opacity: 0.8;
      fill: white;

      :hover {
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: ${BreakpointMobile + 'px'}){
    .footer-row-two {
      grid-template-columns: 1fr;
      grid-template-rows: initial;
      grid-gap: initial;
      height: initial;
      grid-gap: 15px;
  }
  
    .link-column {
      
    }
  }
`

export const CallToActionFooterButton = styled(Button)`
  height: 46px;
  font-family: var(--Font1NotCondensed);
  font-weight: bold;
  width: 140px;
  color: white;
`
