import React from "react"
import PropTypes from "prop-types"
import {
  ColumnOne,
  ColumnTwo,
  Container,
  DownloadOnAppStoreWrapper,
  HeroBackdrop,
  HeroColumnImage,
} from "./styles"
import { AppDownloadButton, LandingSubTitleTypeOne, LandingTitleTypeOne } from "../styles"
import { appDownloadURLS, PageParagraphTypeOne, theme } from "../../GlobalStyle"
import { OutboundLink } from "gatsby-plugin-google-gtag"

function HeroSection({
  heading = "Lorem ipsum dolor sit amet, consectetur add",
  subheading,
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, pulvinar facilisis justo mollis, auctor conse",
  columnImageSrc = theme.images.findanherbHeroImage,
  iosDownloadLink = appDownloadURLS.ios,
  androidDownloadLink = appDownloadURLS.android,
  backdropImage
}) {
  return (
    <Container>
      <ColumnOne>
        <div className="blurb-column">
          <LandingTitleTypeOne marginBottom={subheading? "0px": false}>{heading}</LandingTitleTypeOne>
          {subheading ? (
            <LandingSubTitleTypeOne>{subheading}</LandingSubTitleTypeOne>
          ) : (
            undefined
          )}
          <PageParagraphTypeOne>{description}</PageParagraphTypeOne>
        </div>
        <DownloadOnAppStoreWrapper>
          {iosDownloadLink ? (
            <OutboundLink
              hrefLang="en-us"
              className="download-badge"
              href={iosDownloadLink}
              target="_blank"
            >
              <AppDownloadButton width={'179.547px'} className="badge-img" src={theme.badges.iosDownloadBadge} >
              
              Download App
              </AppDownloadButton>
            </OutboundLink>
          ) : (
            undefined
          )}
          {androidDownloadLink ? (
            <OutboundLink
              hrefLang="en-us"
              href={androidDownloadLink.replace("http://", "https://")}
              target="_blank"
              className="download-badge"
            >
              <AppDownloadButton width={'202.5px'} className="badge-img" src={theme.badges.googlePlayDownloadBadge} >
                Download app
              </AppDownloadButton>
            </OutboundLink>
          ) : (
            undefined
          )}
        </DownloadOnAppStoreWrapper>
      </ColumnOne>
      <ColumnTwo>
        <HeroColumnImage src={columnImageSrc} />
      </ColumnTwo>
    </Container>
  )
}

// Heading should be less than 43 characters
// Description should be less than 141 characters
// columnImageSrc should be
HeroSection.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  description: PropTypes.string.isRequired,
  columnImageSrc: PropTypes.string.isRequired,
  iosDownloadLink: PropTypes.string,
  androidDownloadLink: PropTypes.string,
  backdropImage: PropTypes.string
}

export default HeroSection
