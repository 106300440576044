import { Link } from "gatsby"
import React from "react"
import { FacebookLink, InstagramLink, theme, TwitterLink } from "../../GlobalStyle"
import {
  ABOUT,
  AILMENTS,
  APP_PAGE,
  DISCLAIMER,
  FREQUENTLY_ASKED_QUESTIONS,
  GET_APP,
  HERBS,
  PRIVACY_POLICY,
  SYMPTOM_CHECKER,
  USER_AGREEMENT,
} from "../../routes"
import { StyledFooter, CallToActionFooterButton } from "./styles"
import moment from "moment"
import { navigate } from "gatsby"

function LandingFooter() {
  return (
    <StyledFooter>
      <div className="inner-footer">
        <div className="footer-row-one">
    <div className="brand">
    <img onClick={() => navigate(APP_PAGE)}
            height={40}
            src={theme.logos.findAnHerbLogoWhite}
            className="logo"
          />
    
      </div>
      <div className="call-to-action">
          <CallToActionFooterButton onClick={() => navigate(GET_APP)} color={"secondary"} variant="contained">
            GET THE APP
          </CallToActionFooterButton>
      </div>
        </div>
        <div className="footer-row-two">
          <div className="link-column">
            <Link to={HERBS}>Herbs</Link>
            <Link to={AILMENTS}>Ailments</Link>
            <Link to={SYMPTOM_CHECKER}>Symptom Checker</Link>
          </div>
          <div className="link-column">
            <Link to={ABOUT}>About</Link>
            <Link to={FREQUENTLY_ASKED_QUESTIONS}>
              Frequently Asked Questions
            </Link>
          </div>
          <div className="link-column">
          <Link to={DISCLAIMER}>Disclaimer</Link>

            <Link to={PRIVACY_POLICY}>Privacy Policy</Link>
            <Link to={USER_AGREEMENT}>User agreement</Link>
          </div>
        </div>
        <div className="footer-row-three">
          <div className="copyright-info">
            © {moment().format("YYYY")} FindAnHerb All Rights Reserved.
          </div>
          <div className="social-media-icons">
            <FacebookLink />
            <InstagramLink />
            <TwitterLink />
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default LandingFooter
