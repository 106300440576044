import { Button } from "@material-ui/core"
import styled from "styled-components"
import { Link } from "gatsby"
import { BreakpointMobile } from "../../GlobalStyle"

export const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  height: 100px;
  background: transparent;
  position: absolute;
  top: 0px;
  width: 100%;
    
  .header-inner {
    padding-right: var(--LandingPagePaddingLeftRight);
    padding-left: var(--LandingPagePaddingLeftRight);
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 100%;
    max-width: var(--LandingMaxPageWidth);
    /* border: 1px solid blue; */
    .brand {
      display: flex;
      justify-content: flex-start;
      height: 40px;

     
    }
    .logo{
      height: 40px;
      cursor: pointer;

    }
  }


  
`

export const DesktopNav = styled.nav`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;

  @media only screen and (max-width: ${BreakpointMobile + 'px'}){
    grid-gap: 20px;
  }

  @media only screen and (max-width: ${BreakpointMobile + 'px'}){
    grid-template-columns: 1fr;
  .hide-navlink-on-mobile{
    display: none;
  }
}
`

export const CTAButton = styled(Button)`
  text-transform: initial;
  font-family: var(--Font1NotCondensed);
  font-size: 16px;
  font-weight: bold;
  @media only screen and (max-width: ${BreakpointMobile + 'px'}){
    font-size: 10pt;
  }
`

export const NavLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: var(--Font1NotCondensed);
  color: white;
  font-weight: bold;

 
`


export const NavLinkButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: var(--Font1NotCondensed);
  color: var(--TextTitleColor1);
  text-transform: initial;
  font-weight: bold;

  @media only screen and (max-width: ${BreakpointMobile + 'px'}){
    font-size: 10pt;
  }
`
