import React, { useContext } from "react"
import { theme } from "../../GlobalStyle"
import { APP_PAGE, GET_APP, SYMPTOM_CHECKER } from "../../routes"
import {
  CTAButton,
  DesktopNav,
  NavLink,
  StyledHeader,
  NavLinkButton,
} from "./styles"
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone"
import { navigate } from "gatsby"
import AuthUserContext from "../../../../providers/AuthProvider"

function LandingHeader() {
  const {
    user,
    openSignInModal
  } = useContext(AuthUserContext)

  const handleNavigationToSymptomChecker = () => {
    if (user.id !== "") {
      navigate(SYMPTOM_CHECKER)
    } else {
      openSignInModal(
        "find Natural Remedies based on your symptoms",
        SYMPTOM_CHECKER
      )
    }
  }

  return (
    <StyledHeader>
      <div className="header-inner">
        <div className="brand">
          <img onClick={() => navigate(APP_PAGE)}
            src={theme.logos.findAnHerbLogoDark}
            className="logo"
          />
        </div>

        <DesktopNav>
          <NavLinkButton className="hide-navlink-on-mobile" onClick={() => handleNavigationToSymptomChecker()}>
            Symptom Checker
          </NavLinkButton>
          <CTAButton
            startIcon={<PhoneIphoneIcon />}
            onClick={() => navigate(GET_APP)}
            color="primary"
            variant="outlined"
          >
            Download the app
          </CTAButton>
        </DesktopNav>
      </div>
    </StyledHeader>
  )
}

export default LandingHeader
