import styled from "styled-components"
import Img from "gatsby-image"
import {
  BreakpointMobile,
  BreakpointSmallMobile,
  BreakpointTablet,
} from "../../GlobalStyle"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  max-width: var(--LandingMaxPageWidth);
  padding-left: var(--LandingPagePaddingLeftRight);
  padding-right: var(--LandingPagePaddingLeftRight);
  width: 100%;
  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    grid-template-columns: 1fr;
  }

  .hero-image {
    object-fit: contain;
  }
`

export const HeroBackdrop = styled.div`
  display: block;
  background-image: ${props =>
    props.src ? `background-image: url(${props.src})` : undefined};
  background-size: cover;
  position: absolute;
  /* opacity: 0.2; */
  width: 100%;
  height: 500px;
`

export const Paragraph = styled.p``

export const ColumnOne = styled.div`
  .blurb-column {
  }
`

export const HeroColumnImage = styled.img`
  width: 450px;
  object-fit: contain;
  position: relative !important;

  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    justify-content: center;
  }
`

export const ColumnTwo = styled.div`
  display: flex;
  flex-direction: row-reverse;

  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    justify-content: center;
    display: none;
  }
`

export const DownloadOnAppStoreWrapper = styled.div`
  display: flex;
  height: 60px;
  .download-badge {
    :last-of-type {
      margin-left: 20px;
    }
    height: inherit;
  }

  @media only screen and (max-width: 500px) {
    height: initial;
    .download-badge {
      :last-of-type {
        margin-left: initial;
      }
    }
  }
  @media only screen and (max-width: 450px) {
    height: 45px;
  }
`
